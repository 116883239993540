/* nav css */
nav {
    background-color: #3B4D2D;
    padding: 10px 40px;
}
nav > h1 {
    margin: 0px;
    color: #FFFFFF;
    text-align: center;
}

@media screen and (min-width: 480px) {
    nav > h1 {
        text-align: left;
    }
}