/* main css */
main {
}
/* herosection css */
section.hero {
    padding: 20px 40px;
}
img.heroImage {
    width: 100%;
    border-radius: 50px;
}
h3.heroHeader {
    background-color: #3B4D2D;
    width: 90px;
    border-radius: 5px;
    text-align: center;
    color: #FFFFFF;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: bold;
    font-family: firaSans;
}
p.heroBio {
    font-family: firaSans;
    white-space: pre-line;
}

/* project section */
section.projects {
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #E5E5E5;
}
h3.projectHeader {
    background-color: #3B4D2D;
    width: 90px;
    border-radius: 5px;
    text-align: center;
    color: #FFFFFF;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: bold;
    font-family: firaSans;
}
article.project {
    width: 400px;
    max-width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
article.project > * {
    margin: 5px auto;
}
article.project > img {
    width: 400px;
    border-radius: 10px;
    margin: auto;
    max-width: 80%;
}
article.project > a {
    margin-left: 0px;
    background-color: #3B4D2D;
    border-radius: 5px;
    padding: 5px;
    font-size: 12px;
    color: #FFFFFF;
}

article.project > h4, article.project > p, article.project > a {
    margin: 5px  0px;
    text-decoration: none;
    color: black;
}
article.project > a, article.project > a:visited, article.project > a:active, article.project > a:hover {
    cursor: pointer;
    color: #FFFFFF;
}
article.project > a {
    width: 50px;
    text-align: center;
}

/* work exp section */
section.workExpSec {
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
h3.workExpHeader {
    background-color: #3B4D2D;
    width: 140px;
    border-radius: 5px;
    text-align: center;
    color: #FFFFFF;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: bold;
    font-family: firaSans;
}
article.workExp {
    width: 100%;
    margin: 10px auto;
    display: grid;
    grid-template-areas:    'jobTitle   jobDuration'
                            'jobDesc    jobDesc' ;
    gap: 5px;
}
article > .jobTitle {
    grid-area: jobTitle;
    margin: 0px;
}
article > .jobDuration {
    grid-area: jobDuration;
    text-align: right;
}
article > .jobDesc {
    grid-area: jobDesc;
    white-space: pre-wrap;
}

/* ipad design */
@media screen and (min-width: 480px) {
    section.hero {
        padding: 40px 20%;
    }
    p.heroBio {
        font-size: 24px;
    }
    section.workExpSec {
        padding: 40px 20%;
    }
}

/* desktop design */
@media screen and (min-width: 1400px) {
    section.hero {
        padding: 40px 20%;
        display: flex;
        flex-direction: column;
    }
    img.heroImage {
        max-width: 800px;
        margin: auto;
    }
    p.heroBio {
        font-size: 24px;
    }
    section.workExpSec {
        padding: 40px 20%;
    }

    article.project {
        width: 400px;
    }
    article.project > img {
        width: 100%;
    }
    article.project > p {
        text-align: left;
        margin: 5px 0px;
    }
}
