/* main css */
footer > div {
    background-color: #3B4D2D;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px 10px;
}
footer > div > a > img {
    height: 30px;
    width: 30px;
}
footer > small {
    display: block;
    text-align: center;
    padding: 10px 40px;
    color: #5D5D5D;
}

/* ipad design */
@media screen and (min-width: 480px) {
    footer > div > a > img {
        height: 60px;
        width: 60px;
    }
    footer > small {
        font-size: 16px;
    }
}

/* ipad design */
@media screen and (min-width: 1400px) {
    footer {
        width: 100%;

        display: flex;
        flex-direction: row-reverse;
        justify-content: space-evenly;

    }
    footer > div {
        width: 50%;
    }
    footer > div > a > img {
        height: 45px;
        width: 45px;
    }
    footer > small {
        font-size: 16px;
        width: 50%;
    }
}

